import React from 'react'
import PromotionCategory from 'templates/promotion-category'
import { graphql } from 'gatsby'

const AllPromotions = props => {
  return <PromotionCategory {...props} category='all' />
}

export const pageQuery = graphql`
  {
    allHutsonPromotion(sort: { fields: [endDate], order: ASC }) {
      nodes {
        endDate
        endDateString
        excerpt
        id
        image
        link
        slug
        tags
        title
      }
    }
    allDeereOffer(sort: { fields: [endDate], order: ASC }) {
      nodes {
        endDate
        endDateString: endDate(formatString: "MMMM DD, YYYY")
        excerpt
        id
        image: localImage {
          ...SharpImage640
        }
        slug
        subTitle
        tags
        title
      }
    }
  }
`

export default AllPromotions
